<template>
  <b-card
    v-if="Object.keys(reparatur).length"
    class="blog-edit-wrapper"
  >

    <!-- form -->
    <b-form class="mt-2" @submit.prevent="save">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Klient"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input :readonly="true"
              id="blog-edit-slug"
              :value="filter.client.firstname+' '+filter.client.lastname+', e-mail: '+filter.client.email+', telefon nr 1: '+filter.client.phone1+', telefon nr 2: '+filter.client.phone2"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
        	<span style="display: inline-block; font-size: 2rem; color: #000; padding-bottom: 0.5rem; margin-bottom: 1rem; border-bottom: 2px solid #000; width: 100%;">Adres montażu</span>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Ulica"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input :readonly="true"
              id="blog-edit-slug"
              v-model="filter.address"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Kod pocztowy"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input :readonly="true"
              id="blog-edit-slug"
              v-model="filter.postcode"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Miasto"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input :readonly="true"
              id="blog-edit-slug"
              v-model="filter.city"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Województwo"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input :readonly="true"
              id="blog-edit-slug"
              v-model="filter.state"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Informacje dodatkowe"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-textarea :readonly="true"
              id="blog-edit-slug"
              v-model="filter.client.note"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Nazwa urządzenia"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input :readonly="true"
              id="blog-edit-slug"
              v-model="filter.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Numer seryjny urządzenia"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-input :readonly="true"
              id="blog-edit-slug"
              v-model="filter.serial_number"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Data naprawy"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <flat-pickr
              v-model="reparatur.reparatur_date"
              class="form-control"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:s', locale: 'pl'}"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Serwisant"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <select v-model="reparatur.servicer_id" class="form-control" style="width: 100%;">
              <option v-for="servicer in servicers" :selected="reparatur.servicer_id === servicer.id" :value="servicer.id">{{servicer.firstname}} {{servicer.lastname}}</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Opis naprawy"
            label-for="blog-edit-slug"
            class="mb-2"
          >
            <b-form-textarea
              id="blog-edit-slug"
              v-model="reparatur.note"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            type="submit"
            class="mr-1"
          >
            Zapisz zmiany
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            :to="{name: 'apps-history', query: { id_filter: id_filter, id_client: filter.client_id }}"
          >
            Anuluj
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BMediaAside, BMediaBody, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Polish from 'flatpickr/dist/l10n/pl.js'
import Swal from 'sweetalert2'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormTextarea,
    vSelect,
    quillEditor,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      filter: {
        id: 0,
        name: '',
        guarantee: '',
        installation_date: '',
        time_interval: 0,
        address: '',
        state: '',
        city: '',
        postcode: '',
        services: [],
        serial_number: '',
        representative_id: 0,
        client_id: 0,
        client: {}
      },
      reparatur: {
        id: 0,
        reparatur_date: '',
        note: '',
        filter_id: '0',
        servicer_id: '0',
        client_id: 0,
      },
      snowOption: {
        theme: 'snow',
      },
      filters: [],
      servicers: [],
      id_filter: 0,
      clients: [],
    }
  },
  created() {
    const id = this.$route.params.id ?? 0
    this.id_filter = this.$route.query.id_filter ?? 0
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
    if (id > 0) {
      axios.get(`${process.env.VUE_APP_API_URL}Reparatur/${id}`, config).then(response => {
        this.reparatur = response.data
        if(!this.id_filter)
          this.id_filter = this.reparatur.filter_id;
        this.getFilter()
      }).catch(error => {
        console.log(error)
      })
    } else {
      this.getFilter()
    }
    axios.get(`${process.env.VUE_APP_API_URL}Client`, config).then(response => {
      this.clients = response.data;
    }).catch(error => {
      console.log(error)
    });
    axios.get(`${process.env.VUE_APP_API_URL}Filter`, config).then(response => {
      this.filters = response.data;
    }).catch(error => {
      console.log(error)
    });
    axios.get(`${process.env.VUE_APP_API_URL}Servicer`, config).then(response => {
      this.servicers = response.data;
    }).catch(error => {
      console.log(error)
    });
  },
  methods: {
    getFilter() {
      if (this.id_filter > 0) {
        const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } }
        axios.get(`${process.env.VUE_APP_API_URL}Filter/${this.id_filter}`, config).then(response => {
          this.filter = response.data
          this.reparatur.reparatur_date = this.filter.service_date
          this.reparatur.filter_id = this.filter.id
          this.reparatur.client_id = this.filter.client_id
        }).catch(error => {
          console.log(error)
        })
      }
    },
    save() {
      const config = { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } };
      axios.post(`${process.env.VUE_APP_API_URL}Reparatur`, this.reparatur, config).then(response => {
        this.$router.push({name: 'apps-client', params: { id: this.reparatur.client_id }})
      }).catch(error => {
      	var text = [];
      	for (const [key, value] of Object.entries(error.response.data.data)) {
		  if(value.length > 0) {
		  	text.push(value[0])
		  }
		}
        Swal.fire(
          text.join('<br/>'),
          '',
          'error',
        )
      })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const refPreviewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { refPreviewEl.value.src = base64 })

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
